<template ref="adminUser">
  <div>
      <h3>Контактная информация отдела по работе с ОНК</h3>
      <main>
        <table>
          <tr>
            <td><p>Описание</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr>
            <td><p>{{ $store.getters.getContactInformationOnk.text }}</p></td>
            <td  class="drop-icon">
              <router-link :to="`contact_information_onk/edit`"> Редактировать </router-link>
            </td>
          </tr>
        </table>
      </main>
  </div>
</template>

<script>
export default {
  name: 'ContactInformationOnk',
  mounted () {
    this.$store.dispatch('setContactInformationOnk')
  }
}
</script>

<style scoped lang="scss">
h3{
    font-weight: 600;
    font-size: 1.37rem;
    color: #1F3245;
}
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
  cursor: pointer;
}
</style>
